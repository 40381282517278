// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.application-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.app-status {
    flex: 1 1 12.5%;
    max-width: 45px;
    padding: 1em 0;
    text-align: center;
    color: white;
}
  
.application-header .app-status svg {
    display: none;
}
  
.application-header[app-status-data='0'] .app-status {
    background-color: #ed2724;
}
.application-header[app-status-data='0'] .app-status .error {
    display: initial;
}

.application-header[app-status-data='1'] .app-status {
    background-color: #14a800;
}
.application-header[app-status-data='1'] .app-status .running {
    display: initial;
}

.application-header[app-status-data='2'] .app-status {
    background-color: #58595b;
}
.application-header[app-status-data='2'] .app-status .stale {
    display: initial;
}

.application-header[app-status-data='3'] .app-status {
    background-color: #ff9e22;
}
.application-header[app-status-data='3'] .app-status .api {
    display: initial;
}

.application-title {
    flex: 2 1 55%;
    padding-left: 0.75em;
}
  
  `, "",{"version":3,"sources":["webpack://./src/components/application/Header.css"],"names":[],"mappings":"AAAA;IAGI,aAAa;IAEb,6BAA6B;IAG7B,mBAAmB;AACvB;;AAEA;IAGI,eAAe;IACf,eAAe;IACf,cAAc;IACd,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI,gBAAgB;AACpB;;AAEA;IAGI,aAAa;IACb,oBAAoB;AACxB","sourcesContent":[".application-header {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-pack: distribute;\n    justify-content: space-around;\n    -webkit-box-align: center;\n    -ms-flex-align: center;\n    align-items: center;\n}\n\n.app-status {\n    -webkit-box-flex: 1;\n    -ms-flex: 1 1 12.5%;\n    flex: 1 1 12.5%;\n    max-width: 45px;\n    padding: 1em 0;\n    text-align: center;\n    color: white;\n}\n  \n.application-header .app-status svg {\n    display: none;\n}\n  \n.application-header[app-status-data='0'] .app-status {\n    background-color: #ed2724;\n}\n.application-header[app-status-data='0'] .app-status .error {\n    display: initial;\n}\n\n.application-header[app-status-data='1'] .app-status {\n    background-color: #14a800;\n}\n.application-header[app-status-data='1'] .app-status .running {\n    display: initial;\n}\n\n.application-header[app-status-data='2'] .app-status {\n    background-color: #58595b;\n}\n.application-header[app-status-data='2'] .app-status .stale {\n    display: initial;\n}\n\n.application-header[app-status-data='3'] .app-status {\n    background-color: #ff9e22;\n}\n.application-header[app-status-data='3'] .app-status .api {\n    display: initial;\n}\n\n.application-title {\n    -webkit-box-flex: 2;\n    -ms-flex: 2 1 55%;\n    flex: 2 1 55%;\n    padding-left: 0.75em;\n}\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
